@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+Thai:wght@100..900&display=swap");

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-width: 320px;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.gradient-text {
  background: linear-gradient(
    0deg,
    #dcb978 0%,
    #fff8c7 10%,
    #dcb978 20%,
    #fff8c7 30%,
    #dcb978 40%,
    #fff8c7 50%,
    #dcb978 60%,
    #fff8c7 70%,
    #dcb978 80%,
    #fff8c7 90%,
    #dcb978 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes heroTop {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.91);
  }
}
@keyframes heroMid {
  0%,
  100% {
    top: 125px;
  }
  50% {
    top: 122px;
  }
}
@keyframes heroBot {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.98);
    opacity: 0.5;
  }
}
